import React from "react";
import { createUseStyles } from "react-jss";
import { Outlet, useNavigate } from "react-router-dom";
import { MainSidebar } from "../components/MainSidebar";
import { ReactComponent as IconNext } from "../assets/svg/icon-chevron-right.svg";

type ProfilePageProps = {};

const pageStyles = createUseStyles({
  mainHeader: {},
  mainSidebar: {},
  mainContent: {
    width: "100%",
    padding: 0,
  },
  listHolder: {
    listStyle: "none",
    margin: "0 0 20px 0",
    padding: 0,
    "& li:nth-child(1)": {
      borderTop: "0 !important",
    },
  },
  listItem: {
    fontSize: 14,
    fontWeight: 500,
    padding: "8px 20px",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    opacity: 0.8,
    "&:first-item": {
      borderTop: 0,
    },
    "&:hover": {
      background: "#f8f8f8",
      cursor: "pointer",
    },
  },
  activeItem: {
    background: "#F5F5F5",
  },
  itemInfo: {
    display: "flex",
  },
});

const profileNav = [
  {
    name: "General",
    path: "/profile/general",
    key: "gn",
  },
  {
    name: "Security",
    path: "/profile/security",
    key: "sc",
  },
  {
    name: "Notifications",
    path: "/profile/notifications",
    key: "nt",
  },
];

export const ProfilePage: React.FC<ProfilePageProps> = ({}) => {
  const nav = useNavigate();
  const classes = pageStyles();

  return (
    <div className={"mainWrapper"}>
      <MainSidebar>
        <ul className={classes.listHolder}>
          {profileNav.map((item, index) => {
            return (
              <li
                key={item.key}
                className={classes.listItem}
                style={{
                  border: "1px solid #E5E5E5",
                  borderTopWidth: index === 0 ? 1 : 0,
                  borderLeft: 0,
                  borderRight: 0,
                  paddingLeft: 20,
                }}
                onClick={() => nav(item.path)}
              >
                <div className={classes.itemInfo}>
                  <div>{item.name}</div>
                </div>
                <IconNext />
              </li>
            );
          })}
        </ul>
      </MainSidebar>
      <div className={classes.mainContent}>
        <Outlet />
      </div>
    </div>
  );
};
