import React from "react";
import { ReactComponent as IconChevron } from "../assets/svg/icon-chevron-right.svg";
import { IDocType } from "../models/IDocType";
import { FileCard } from "./FileCard";
import gsap from "gsap";


type DocumentsListProps = {
  listTitle: string;
  collapsable?: boolean;
  documents?: IDocType[];
};

export const DocumentsList: React.FC<DocumentsListProps> = ({
  documents,
  listTitle,
  collapsable,
}) => {
  const arrowRef = React.useRef<SVGSVGElement>(null);
  const [listOpened, setListOpened] = React.useState<boolean>(true);

  const toggleList = () => {
    if (!collapsable) return;
    setListOpened(!listOpened);
    arrowAnimation();
  };

  const arrowAnimation = () => {
    gsap.to(arrowRef.current, {
      duration: 0.2,
      rotate: listOpened ? -90 : 90,
      ease: "power2.inOut",
    });
  }

  return (
    <div
      style={{
        marginBottom: 20,
      }}
    >
      <div
        className={"listTitle"}
        style={{
          marginBottom: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: 16,
          fontFamily: "Montserrat, sans-serif",
          userSelect: "none",
          cursor: collapsable ? "pointer" : "default",
        }}
        onClick={toggleList}
      >
        {listTitle}
        <IconChevron
          ref={arrowRef}
          style={{
            opacity: 0.6,
            transform: listOpened ? "rotate(-90deg)" : "rotate(90deg)",
          }}
        />
      </div>

      {listOpened && (
        <>
          {documents?.map((doc) => {
            return (
              <FileCard
                key={doc.id}
                title={doc.title}
                fromDate={doc.fromDate}
                expireDate={doc.expires}
                docType={doc.type}
              />
            );
          })}
        </>
      )}
    </div>
  );
};
