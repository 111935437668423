import React, { PropsWithChildren } from "react";
import { createUseStyles } from "react-jss";
import { BaseCard } from "./BaseCard/BaseCard";

type MainSidebarProps = {
  withPadding?: boolean;
  withShadow?: boolean;
};

const barStyles = createUseStyles({
  sidebarHolder: {
    maxWidth: 280,
    width: "100%",
  },
});

export const MainSidebar: React.FC<PropsWithChildren & MainSidebarProps> = ({
  children,
  withPadding,
  withShadow,
}) => {
  const classes = barStyles();
  return (
    <div className={classes.sidebarHolder}>
      <BaseCard withPadding={withPadding} withShadow={withShadow}>
        {children}
      </BaseCard>
    </div>
  );
};
