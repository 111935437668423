import { PopupButton } from "@typeform/embed-react";
import { onValue, get } from "firebase/database";
import React, { useEffect, useLayoutEffect } from "react";
import { createUseStyles } from "react-jss";
import { firebaseService } from "../services/firebase.service";

type WaitlistFormProps = {
  onSubmit?: (email: string) => void;
};

const waitlistStyles = createUseStyles({
  formHolder: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
    margin: "20px 0",
  },
  formCaption: {
    width: "100%",
    fontSize: 12,
    textAlign: "center",
    marginTop: -12,
    "@media(max-width: 414px)": {
      width: "90%",
      fontSize: 14,
      marginTop: -8,
    },
  },
  mailInput: {
    border: "1px solid #4FFFC0",
    backgroundColor: "transparent",
    padding: "10px 20px",
    borderRadius: 4,
    color: "#fff",
    fontSize: "1rem",
    "@media(max-width: 414px)": {
      fontSize: 12,
    },
  },
  submitBtn: {
    padding: "10px 20px",
    backgroundColor: "#4FFFC0",
    color: "#000",
    borderRadius: 4,
    cursor: "pointer",
    transition: "background-color 0.2s ease-in-out",
    fontSize: "1rem",
    border: "none",
    WebkitAppearance: "none",
    "&:hover": {
      backgroundColor: "#19F2E5",
    },
    "@media(max-width: 414px)": {
      fontSize: 12,
    },
  },
});
export const WaitlistForm: React.FC<WaitlistFormProps> = ({ onSubmit }) => {
  const [isSending, setIsSending] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [operationMessage, setOperationMessage] = React.useState<string>("");
  const classes = waitlistStyles();

  const handleEmailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isSending) return;
    if (e.key === "Enter") {
      if (emailIsValid()) {
        onSubmit?.(email);
        handleSubmit();
      }
    }
  };

  const handleSubmit = () => {
    if (isSending) return;
    setIsSending(true);
    if (emailIsValid()) {
      onSubmit?.(email);
      firebaseService
        .addEmailToWaitlist(email)
        .then(() => {
          setEmail("");
          setOperationMessage("Email added to waitlist");
        })
        .catch((err) => {
          setOperationMessage("Something went wrong");
        })
        .finally(() => setIsSending(false));
    }
  };

  const emailIsValid = (): boolean => {
    //check if email is valid with regex
    const regex = /\S+@\S+\.\S+/;
    if (!regex.test(email)) {
      alert("Please enter a valid email");
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!isSending && operationMessage && email === "") {
      setTimeout(() => {
        setIsSending(false);
        setOperationMessage("");
      }, 3000);
    }
  }, [isSending]);

  return (
    <>
      {/* <div className={classes.formHolder}>
        <input
          required
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={handleEmailChanged}
          className={classes.mailInput}
          onKeyDown={handleKeyDown}
        />
        <div className={classes.submitBtn} onClick={handleSubmit}>
          {isSending ? "Adding you..." : "Join waitlist"}
        </div>
      </div> */}
      <PopupButton
        id="uGxYyHWt"
        style={{ fontSize: 20, maxWidth: 300, margin: "40px auto" }}
        className={classes.submitBtn}
      >
        Join waitlist
      </PopupButton>
      {/* <p className={classes.formCaption}>
        {operationMessage
          ? operationMessage
          : "Leave your email and we will contact you to provied early access"}
      </p> */}
    </>
  );
};
