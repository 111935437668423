import { Plane } from "@react-three/drei";
import React from "react";
import { PlaneGeometry, Color, DoubleSide, MeshStandardMaterial } from "three";

export const BgPlane = () => {
  const material = new MeshStandardMaterial({
    color: new Color("black"),
    side: DoubleSide,
    metalness: 0.3,
    roughness: 0.7,
    // wireframe: true,
  });
  const geometry = new PlaneGeometry(120, 120, 10, 10);

  return (
    <mesh geometry={geometry} material={material} position={[0, 0, -10]} />
  );
};
