import React from "react";

type BadgeProps = {
  text?: string;
  variant?: "success" | "error" | "warning" | "info";
  size?: "small" | "default" | "large";
};

export const Badge: React.FC<BadgeProps> = ({ text, variant, size = 'default' }) => {
  return (
    <div
      style={{
        transform: `scale(${size === 'small' ? 0.8 : size === 'large' ? 1.2 : 1})`,
        backgroundColor:
          variant === "success"
            ? "#4caf5020"
            : variant === "error"
            ? "#f4433620"
            : variant === "warning"
            ? "#ff980020"
            : variant === "info"
            ? "#2196f320"
            : "#fff",
        padding: "5px 20px",
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '18px',
        borderRadius: 22,
        textTransform: "lowercase",
        color:
          variant === "success"
            ? "#0AA88D"
            : variant === "error"
            ? "#A80A0A"
            : variant === "warning"
            ? "#A8850A"
            : variant === "info"
            ? "#0A40A8"
            : "#000",
      }}
    >
      {text}
    </div>
  );
};
