import React, { useRef } from "react";
import { MathUtils } from "three";
import { useControls } from "leva";
import { useFrame, useThree } from "@react-three/fiber";

export const PointerLights = () => {
  const viewport = useThree((state) => state.viewport);
  const { width, height, top, left, aspect } = viewport;
  const widthDelta = width - width / 2;
  const heightDelta = height - height / 2;

  const pointerLight1Ref = useRef<any>(null);
  const pointerLight2Ref = useRef<any>(null);
  const pointerLight3Ref = useRef<any>(null);

  // useHelper(pointerLight1Ref, PointLightHelper, 0.1, "#3BFCB6");
  // useHelper(pointerLight2Ref, PointLightHelper, 0.1, "#19F2E5");
  // useHelper(pointerLight3Ref, PointLightHelper, 0.1, "red");

  const pl1Controls = useControls({
    intensity1: { value: 4, min: 0, max: 5 },
    color1: "#3BFCB6",
    pos1: { x: -20.4, y: 9.8, z: 3.8 },
    decay1: { value: 2, min: 0, max: 20, step: 0.2 },
    dist1: { value: 0, min: 0, max: 20, step: 1 },
  });

  const pl2xPos = left + widthDelta + (9.2 / width) * aspect;

  const pl2Controls = useControls({
    intensity2: { value: 0.5, min: 0, max: 1 },
    color2: "#19F2E5",
    pos2: { x: pl2xPos, y: 3, z: 2 },
    decay2: { value: 2, min: 0, max: 10, step: 0.2 },
    dist2: { value: 0, min: 0, max: 20, step: 1 },
  });

  const pl3xPos = left + widthDelta + (7.2 / width) * aspect;
  const pl3yPos = top + heightDelta + (-4.2 / height) * aspect;

  const pl3Controls = useControls({
    intensity3: { value: 1.4, min: 0, max: 1 },
    color3: "#19F2E5",
    pos3: { x: pl3xPos, y: -pl3yPos, z: 2 },
    decay3: { value: 2, min: 0, max: 10, step: 0.2 },
    dist3: { value: 0, min: 0, max: 20, step: 1 },
  });

  useFrame(({ clock }, delta) => {
    const time = clock.getElapsedTime();
    const intensity = MathUtils.clamp(1.4 + Math.sin(time / 1.2), 1.4, 2.4);
    const intensity2 = MathUtils.clamp(1.8 + Math.sin(time / 1.2), 1.8, 3.5);

    if (pointerLight1Ref.current) {
      pointerLight1Ref.current.position.x = Math.sin(time) - 18;
      pointerLight1Ref.current.position.z = Math.cos(time) / 1.02;
      pointerLight1Ref.current.intensity = intensity;
    }
    if (pointerLight2Ref.current) {
      pointerLight2Ref.current.position.z = -6.7 + Math.cos(time) / 2.02;
      pointerLight2Ref.current.intensity = intensity2;
    }
    if (pointerLight3Ref.current) {
      pointerLight3Ref.current.position.z = -6.7 + Math.cos(time) / 2.02;
      pointerLight3Ref.current.position.y = -pl3yPos - 2 + Math.cos(time);
    }
  });

  return (
    <group>
      <pointLight
        ref={pointerLight1Ref}
        position={[pl1Controls.pos1.x, pl1Controls.pos1.y, pl1Controls.pos1.z]}
        intensity={pl1Controls.intensity1}
        color={pl1Controls.color1}
        distance={pl1Controls.dist1}
        decay={pl1Controls.decay1}
      />
      <pointLight
        ref={pointerLight2Ref}
        position={[pl2Controls.pos2.x, pl2Controls.pos2.y, pl2Controls.pos2.z]}
        intensity={pl2Controls.intensity2}
        color={pl2Controls.color2}
        decay={pl2Controls.decay2}
        distance={pl2Controls.dist2}
      />
      <pointLight
        ref={pointerLight3Ref}
        position={[pl3Controls.pos3.x, pl3Controls.pos3.y, pl3Controls.pos3.z]}
        intensity={pl3Controls.intensity3}
        color={pl3Controls.color3}
        decay={pl3Controls.decay3}
        distance={pl3Controls.dist3}
      />
    </group>
  );
};
