import { Canvas } from "@react-three/fiber";
import React, { useEffect, useRef, useState } from "react";
import { BgPlane } from "../components/3dComponents/BgPlane";
import { WavingSphere } from "../components/3dComponents/WavingSphere";
import { PointerLights } from "../components/lights/PointerLights";

export const LandingScene = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [sizes, setSizes] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    aspect: Math.round(window.innerWidth / window.innerHeight),
  });

  const handleResize = () => {
    setSizes({
      width: window.innerWidth,
      height: window.innerHeight,
      aspect: Math.round(window.innerWidth / window.innerHeight),
    });
  };

  useEffect(() => {
    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    return () => {
      // Clean up event listener on component unmount
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Canvas
      ref={canvasRef}
      resize={{
        scroll: false,
        debounce: { scroll: 150, resize: 0 },
      }}
      camera={{
        position: [0, 0, 10],
        fov: 55,
        near: 1,
        far: 100,
      }}
    >
      <BgPlane />
      <PointerLights />
      <ambientLight />
      <WavingSphere />

      {/* <OrbitControls /> */}
    </Canvas>
  );
};
