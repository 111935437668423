// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from "firebase/app";
import { DatabaseReference, getDatabase, ref, get, Database, } from 'firebase/database';
import { collection, addDoc, getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDCCDNZUmEu0Ofc1m194QWE_M6ERQLyGmo",
  authDomain: "accrede-99620.firebaseapp.com",
  projectId: "accrede-99620",
  storageBucket: "accrede-99620.appspot.com",
  messagingSenderId: "605729850154",
  appId: "1:605729850154:web:c2e468d32ae0ac0f3c1453",
  databaseUrl: "https://accrede-99620-default-rtdb.firebaseio.com",
};


class FirebaseService {
  public fireApp: FirebaseApp;
  public db: Database;
  private analytics: any;


  constructor() {
    // Initialize Firebase
    this.fireApp = initializeApp(firebaseConfig, 'accrede');
    // this.analytics = getAnalytics(this.fireApp);
    this.db = getDatabase(this.fireApp);
  }

  addEmailToWaitlist(email: string) {
    const store = getFirestore(this.fireApp);
    return addDoc(collection(store, "waitlist"), {
      email: email,
    });

  }

  getDbRef(): DatabaseReference {
    return ref(this.db, 'waitlist');
  }

  getWaitlist(): Promise<any[]> {
    const query = ref(this.db, 'waitlist');
    return get(query).then((snapshot) => {
      if (snapshot.exists()) {
        const waitlistData: any[] = [];
        snapshot.forEach((childSnapshot) => {
          const email = childSnapshot.val().email;
          waitlistData.push(email);
        });
        return waitlistData;
      } else {
        return [];
      }
    }).catch((error) => {
      console.error('Error retrieving waitlist data:', error);
      return [];
    });
  }
}

export const firebaseService = new FirebaseService();