import React from 'react'

import {ReactComponent as IconSearch} from "../../assets/svg/icon-search.svg";
import styles from "./SearchInput.module.scss";

type SearchInputProps = {
  valueChanged: (value: string) => void,
}

export const SearchInput: React.FC<SearchInputProps> = ({valueChanged}) => {
  return (
    <div className={styles.searchHolder}>
      <IconSearch />
      <input type={"search"} onChange={(e) => valueChanged(e.target.value)} />
    </div>
  )
}
