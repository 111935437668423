import React, { useState } from "react";
import styles from "./ChatBox.module.scss";
import { ReactComponent as IconSend } from "../../assets/svg/icon-send.svg";

type ChatInputProps = {
  valueChanged?: (text: string) => void;
  onSend: (message: string) => void;
  style?: React.CSSProperties;
};

export const ChatInput: React.FC<ChatInputProps> = ({
  valueChanged,
  onSend,
  style,
}) => {
  const [message, setMessage] = useState("");

  const handleInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMessage(value);
    valueChanged && valueChanged(value);
  };

  const handleSend = (e: any) => {
    if (e.key === "Enter" || e.type === "click") {
      onSend(message);
      setMessage("");
    }
  };

  return (
    <div className={styles.chatInputHolder} style={{
      width: '60%',
      ...style,
    }}>
      <input
        type={"text"}
        value={message}
        placeholder={"Say hello"}
        onChange={handleInputChanged}
        onKeyDown={handleSend}
      />
      <div className={styles.sendBtn} onClick={handleSend}>
        <IconSend />
      </div>
    </div>
  );
};
