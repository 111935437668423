import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styles from "../app.module.scss";
import { ChatInput } from "../components/ChatBox/ChatInput";
import { AppHeader } from "../components/layout/AppHeader";
import { ReactComponent as ChatIcon } from "../assets/svg/icon-chat.svg";

export const AuthPage = () => {
  const nav = useNavigate();

  useEffect(() => {
    nav("/sign-in", { replace: true });
  }, []);

  return (
    <div className="App">
      <AppHeader showNav={false} />

      <div className={styles.content}>
        <Outlet />

        <div className={styles.miniChat}>
          <div className={styles.miniChatHeader}>
            <ChatIcon width={26} height={26} style={{ marginRight: 10 }} />
            We’re here to help,
            <br /> ask anything
          </div>
          <ChatInput
            onSend={() => null}
            style={{
              width: "100%",
            }}
          />
        </div>
      </div>
    </div>
  );
};
