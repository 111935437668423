import React from "react";

import { ReactComponent as IconDoc } from "../assets/svg/icon-doc-file.svg";
import { ReactComponent as IconContract } from "../assets/svg/icon-smart-contract.svg";
import { ReactComponent as IconDownload } from "../assets/svg/icon-download.svg";
import { ReactComponent as IconPreview } from "../assets/svg/icon-preview.svg";
import dayjs from "dayjs";
import { MAIN_DATE_FORMAT } from "../constants/formats";

type FileCardProps = {
  title: string;
  fromDate: number;
  expireDate?: number;
  docType: "file" | "smartContract";
};

export const FileCard: React.FC<FileCardProps> = ({
  title,
  fromDate,
  expireDate,
  docType,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row nowrap",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        background:
          docType === "file" ? "rgba(109,188,232,.23)" : "rgba(81,178,97,.23)",
        padding: "8px 10px",
        borderRadius: 6,
        marginBottom: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          alignContent: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            fontWeight: 500,
            marginBottom: 4,
          }}
        >
          {docType === "file" ? (
            <IconDoc width={20} height={20} />
          ) : (
            <IconContract width={20} height={20} />
          )}
          <span style={{ marginLeft: 4 }}>{title}</span>
        </div>

        <div
          style={{
            fontSize: 12,
            opacity: 0.5,
            fontWeight: 500,
            marginLeft: 3,
          }}
        >
          {docType === "file"
            ? "From date " + dayjs(fromDate).format(MAIN_DATE_FORMAT)
            : "Expires " + dayjs(expireDate).format(MAIN_DATE_FORMAT)}
        </div>
      </div>

      <div
        style={{
          marginLeft: "auto",
          cursor: "pointer",
        }}
      >
        <IconDownload width={18} height={18} style={{ marginRight: 4 }} />
        <IconPreview width={18} height={18} />
      </div>
    </div>
  );
};
