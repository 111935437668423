import { Stats } from "@react-three/drei";
import { Leva } from "leva";
import React from "react";
import { createUseStyles } from "react-jss";
import { LandingScene } from "../scenes/LandingScene";
import gsap from "gsap";
import { ReactComponent as Logo } from "../assets/svg/logo-2.svg";
import { WaitlistForm } from "../components/WaitlistForm";

const landingStyles = createUseStyles({
  pageHolder: {
    height: "100vh",
    width: "100vw",
    background: "#002012",
  },
  uiHolder: {
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 1,
    width: "100vw",
    height: "100vh",
    color: "#fff",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexFlow: "column nowrap",
    paddingLeft: 60,
    overflow: "auto",
    userSelect: "none",
    "@media(max-width: 414px)": {
      paddingLeft: 0,
      alignContent: "center",
      alignItems: "center",
    },
  },
  titles: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    marginTop: "12%",
    marginBottom: 20,
    "& > h1": {
      fontWeight: "700",
      fontSize: "7.5em",
      letterSpacing: "-0.02em",
      maxWidth: "calc(55% + 60px)",
      "& span": {
        color: "#4FFFC0",
      },
    },
    "& > h2": {
      maxWidth: "calc(55% + 60px)",
      fontWeight: "700",
      fontSize: "4.5em",
      letterSpacing: "-0.02em",
      opacity: 0.75,
    },
    "@media(max-width: 414px)": {
      alignItems: "center",
      marginTop: "25%",
      "& > h1": {
        fontSize: "5em",
        maxWidth: "80%",
        textAlign: "center",
        marginBottom: 20,
        lineHeight: ".9",
      },
      "& > h2": {
        fontSize: "4em",
        maxWidth: "80%",
        textAlign: "center",
        lineHeight: ".9",
      },
    },
  },
  statsHolder: {
    position: "fixed",
    left: 0,
    top: 0,
    "> canvas:first-of-type": {
      marginTop: 140,
    },
  },
  canvasHolder: {},
  mainCanvas: {
    marginTop: 120,
  },
});

export const LandingPage = () => {
  const classes = landingStyles();

  return (
    <div className={classes.pageHolder}>
      <Leva isRoot hidden />
      <div className={classes.uiHolder}>
        {/* <div className={classes.statsHolder}>
          <Stats />
        </div> */}
        <div className={classes.titles}>
          <Logo
            style={{
              marginBottom: 40,
            }}
          />
          <h1>
            Blockchain <span>Letter of Credit</span>
          </h1>
          <h2>B2B global trade solutions</h2>
          <WaitlistForm />
        </div>
      </div>
      <LandingScene />
    </div>
  );
};
