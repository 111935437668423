import React from "react";
import { createUseStyles } from "react-jss";
import { BaseButton } from "../components/BaseButton";
import { BaseCard } from "../components/BaseCard/BaseCard";
import { BaseInput } from "../components/BaseInput";
import { MainSidebar } from "../components/MainSidebar";
import styles from "./AnalyticsCenterPage.module.scss";

const analyticStyles = createUseStyles({
  holder: {
    width: "100%",
    padding: 0,
  },
});

export const AnalyticsCenterPage = () => {
  const classes = analyticStyles();
  return (
    <div className={"mainWrapper"}>
      <MainSidebar withPadding>
        <BaseInput placeholder="dd/mm/yyyy" label="From date" />
        <BaseInput placeholder="dd/mm/yyyy" label="To date" />
        <BaseButton title="build new report" />
      </MainSidebar>

      <div className={classes.holder}>
        <BaseCard withPadding>
          <div className={styles.contentSideArea}>
            <div className={styles.contentTitle}>Extract data easy</div>
            <BaseInput
              placeholder="Title, id, country amont anything..."
              label="Search by"
            />
          </div>
        </BaseCard>
      </div>
    </div>
  );
};
