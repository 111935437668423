import React, { useCallback, useEffect, useRef, useState } from "react";
import { BaseCard } from "../components/BaseCard/BaseCard";
import { ChatInput } from "../components/ChatBox/ChatInput";
import { LettersList } from "../components/LettersList/LettersList";
import { MainSidebar } from "../components/MainSidebar";
import { SearchInput } from "../components/SearchInput/SearchInput";
import styles from "./MessengerPage.module.scss";

const mockList = [
  {
    id: "420MS181",
    info: "Subtitle here",
  },
  {
    id: "139AA523",
    info: "Subtitle here",
  },
  {
    id: "219AF521",
    info: "Subtitle here",
  },
];

export const MessengerPage = () => {
  const [messages, setMessages] = useState<any[]>([]);
  const [chatList, setChatList] = useState<any[]>([]);
  const [selectedChatId, setSelectedChatId] = useState("");
  const chatMessagesHolderRef = useRef<HTMLDivElement>(null);
  const scrollerRef = useRef<HTMLDivElement>(null);
  const handleSearchRequest = (value: string) => {
    // console.log(value);
  };

  useEffect(() => {
    setChatList(mockList);
    setSelectedChatId(mockList[0].id);
  }, []);

  const handleListItemClicked = (itemId: string) => {
    setSelectedChatId(itemId);
  };

  const handleMessageSend = (message: string) => {
    setMessages([
      ...messages,
      {
        id: +new Date(),
        message,
        author: "me",
      },
    ]);
  };

  const updateContainerHeight = useCallback(() => {
    if (chatMessagesHolderRef.current) {
      scrollerRef.current?.setAttribute(
        "style",
        `height: ${
          chatMessagesHolderRef?.current?.getBoundingClientRect().height - 140
        }px`
      );
    }
  }, [window.innerHeight, window.innerWidth]);

  useEffect(() => {
    updateContainerHeight();
    window.addEventListener("resize", updateContainerHeight);
    return () => window.removeEventListener("resize", updateContainerHeight);
  }, []);

  return (
    <div className={"mainWrapper"}>
      <MainSidebar>
        <div style={{ padding: 20 }}>
          <SearchInput valueChanged={handleSearchRequest} />
        </div>
        <LettersList
          listTitle={"Updates"}
          itemClicked={handleListItemClicked}
          lettersList={chatList}
          selectedItemId={selectedChatId}
        />
      </MainSidebar>

      <div className={styles.messengerContentArea}>
        <BaseCard>
          <div style={{ padding: 20 }}>
            <div className={styles.messagesWrapper}>
              <div ref={scrollerRef} className={styles.messagesScroller}>
                <div className={styles.messagesHolder}>
                  {messages.map((message) => (
                    <div
                      key={message.id}
                      className={
                        styles.messageHolder +
                        " " +
                        (message.author === "me" ? styles.myMessage : "")
                      }
                    >
                      <div className={styles.messageText}>
                        {message.message}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <ChatInput onSend={handleMessageSend} />
            </div>
          </div>
        </BaseCard>
      </div>
    </div>
  );
};
