import React from "react";
import { useNavigate } from "react-router-dom";
import { BaseButton } from "../components/BaseButton";
import { BaseCard } from "../components/BaseCard/BaseCard";
import { BaseInput } from "../components/BaseInput";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { setIsAuth } from "../store/auth.slice";
import styles from "./auth.module.scss";

export const SigninPage = () => {
  const dispatch = useAppDispatch()
  const nav = useNavigate();

  const handleLogin = () => {
    dispatch(setIsAuth(true))
    nav('/transparent-office')
  }

  return (
    <div className={styles.pageHolder}>
      <div className={styles.authFormWrapper}>
        <BaseCard withPadding withShadow>
          <div className={styles.authFormHolder}>
            <BaseInput label={"Email"} placeholder={"Enter your email"} />
            <BaseInput
              label={"Password"}
              placeholder={"Enter your password"}
              inputType={"password"}
            />
            <div className={styles.actions}>
              <BaseButton title="Log in" onClick={handleLogin}/>
              <BaseButton title="sign up / Upload contract" secondary />
            </div>
          </div>
        </BaseCard>
      </div>
    </div>
  );
};
