import React from "react";
import styles from "./TransparentOfficePage.module.scss";
import { BaseCard } from "../components/BaseCard/BaseCard";
import { SearchInput } from "../components/SearchInput/SearchInput";
import { LettersList } from "../components/LettersList/LettersList";
import { ReactComponent as BuyIcon } from "../assets/svg/icon-buy.svg";
import { ReactComponent as SellIcon } from "../assets/svg/icon-sell.svg";
import { ReactComponent as UsersIcon } from "../assets/svg/icon-users.svg";
import { ReactComponent as DocIcon } from "../assets/svg/icon-file.svg";
import { ReactComponent as FolderIcon } from "../assets/svg/icon-folder.svg";
import { ReactComponent as IconWallet } from "../assets/svg/icon-wallet.svg";
import { FileCard } from "../components/FileCard";
import { ParticipantCard } from "../components/ParticipantCard";
import { DocumentsList } from "../components/DocumentsList";
import dayjs from "dayjs";
import {
  _mockLogisticDocs,
  _mockSupplierDocs,
  _mockSurveorDocs,
} from "../mocks/data";
import { Badge } from "../components/Badge";
import { MainSidebar } from "../components/MainSidebar";

const openedByMe = [
  {
    id: "139AA523",
    info: "DATE: 03/20/2023",
  },
  {
    id: "420MS181",
    info: "DATE: 01/18/2023",
  },
];

const openedForMe = [
  {
    id: "219AF521",
    info: "DATE: 01/22/2023",
  },
  {
    id: "129GA513",
    info: "DATE: 06/14/2023",
  },
  {
    id: "639CA713",
    info: "DATE: 02/18/2021",
  },
];

export const TransparentOfficePage = () => {
  const [selectedRoomId, setSelectedRoomId] = React.useState<string>(
    openedByMe[0].id
  );
  const [roomsList, setRoomsList] = React.useState<any[]>([
    openedByMe,
    openedForMe,
  ]);
  const handleSearchRequest = (value: string) => {
    // console.log(value);
  };

  const handleListItemClicked = (itemId: string) => {
    setSelectedRoomId(itemId);
  };

  return (
    <div className={"mainWrapper"}>
      <MainSidebar>
        <div style={{ padding: 20 }}>
          <SearchInput valueChanged={handleSearchRequest} />
        </div>
        <LettersList
          withLines
          listTitle={"Opened by you"}
          titleIcon={<BuyIcon width={20} height={20} />}
          itemClicked={handleListItemClicked}
          lettersList={roomsList[0]}
          selectedItemId={selectedRoomId}
        />
        <LettersList
          withLines
          listTitle={"Opened in your favour"}
          titleIcon={<SellIcon />}
          itemClicked={handleListItemClicked}
          lettersList={roomsList[1]}
          selectedItemId={selectedRoomId}
        />
      </MainSidebar>

      <div className={styles.officeContentArea}>
        <BaseCard>
          <div className={styles.roomHeader}>
            <div className={styles.roomHeaderInfo}>
              <div className={styles.roomHeaderInfoTitle}>
                Letter of Credit #{selectedRoomId}
              </div>
              <div className={styles.roomHeaderInfoSubTitle}>
                Date: <span>03/20/2023</span> Exp: <span>05/20/2023</span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 14,
                  lineHeight: "14px",
                }}
              >
                Status:{" "}
                <Badge variant={"info"} text={"verification"} size={"small"} />
              </div>
            </div>
            <div className={styles.roomHeaderInfoWallet}>
              <div className={styles.roomHeaderInfoWalletTitle}>
                <IconWallet />
                <div className={styles.roomHeaderInfoWalletTitleText}>
                  5PCXQYF7HZ5bRpxnGs287wATE2F5bhrzRmb3F7nY7JgZ
                </div>
              </div>
              <div className={styles.roomHeaderInfoWalletAmount}>
                <Badge variant={"info"} text={"to hold"} size={"small"} />
                540,000 USDT
              </div>
            </div>
          </div>

          <div className={styles.roomContent}>
            {/** Participants */}
            <div>
              <div className={styles.roomContentTitle}>
                <UsersIcon />
                Participants
              </div>

              <div className={styles.roomContentInfo}>
                <ParticipantCard
                  role="buyer"
                  title="Wood Antique LLC"
                  verified
                />
                <ParticipantCard
                  role="supplier"
                  title="Mijatovic Ltd"
                  verified
                />
                <ParticipantCard
                  role="surveyor"
                  title="Wilm Snellenberg..."
                  verified
                />
                <ParticipantCard
                  role={"logistician"}
                  title="Allsta express"
                  verified={false}
                />
              </div>
            </div>
            {/** PARTICIPANTS_END */}

            {/** LC Data */}
            <div>
              <div className={styles.roomContentTitle}>
                <DocIcon />
                LC Data
              </div>

              <div className={styles.roomContentInfo}>
                <FileCard
                  title="Contract file"
                  docType={"file"}
                  fromDate={dayjs("02/23/2023", "MM/DD/YYYY").valueOf()}
                />
                <FileCard
                  title="Smart contract"
                  docType={"smartContract"}
                  fromDate={dayjs("02/23/2023", "MM/DD/YYYY").valueOf()}
                  expireDate={dayjs("05/23/2023", "MM/DD/YYYY").valueOf()}
                />
              </div>
            </div>
            {/** LC_DATA_END */}

            {/** Files */}
            <div>
              <div className={styles.roomContentTitle}>
                <FolderIcon />
                Files
              </div>

              <div className={styles.roomContentInfo}>
                <DocumentsList
                  documents={_mockSurveorDocs}
                  collapsable
                  listTitle="Surveyor"
                />
                <DocumentsList
                  documents={_mockLogisticDocs}
                  collapsable
                  listTitle="Logistic"
                />
                <DocumentsList
                  documents={_mockSupplierDocs}
                  collapsable
                  listTitle="Supplier"
                />
              </div>
            </div>
            {/** FILES_END */}
          </div>
        </BaseCard>
      </div>
    </div>
  );
};
