import React from "react";

export const AvatarsGroup = ({...props}: any) => {
  return (
    <ul className="avatars" style={{...props.style}}>
      <li>
        <div className="avatar">
          <img src="https://randomuser.me/api/portraits/women/21.jpg" alt="" />
        </div>
      </li>
      <li>
        <div className="avatar">
          <img src="https://randomuser.me/api/portraits/women/82.jpg" alt="" />
        </div>
      </li>
      <li>
        <div className="avatar">
          <img src="https://randomuser.me/api/portraits/women/57.jpg" alt="" />
        </div>
      </li>
      <li>
        <div className="avatar">
          <img src="https://randomuser.me/api/portraits/women/0.jpg" alt="" />
        </div>
      </li>
    </ul>
  );
};
