import React from "react";
import styles from "./app.module.scss";
import { Navigate, Outlet } from "react-router";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { NotFoundPage } from "./pages/NotFoundPage";
import { MessengerPage } from "./pages/MessengerPage";
import { TransparentOfficePage } from "./pages/TransparentOfficePage";
import { AnalyticsCenterPage } from "./pages/AnalyticsCenterPage";
import { AuthPage } from "./pages/AuthPage";
import { SignupPage } from "./pages/SignupPage";
import { SigninPage } from "./pages/SigninPage";
import { AppHeader } from "./components/layout/AppHeader";
import { selectAuthState } from "./store/auth.slice";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import { LandingPage } from "./pages/LandingPage";
import { ProfilePage } from "./pages/ProfilePage";

const landingRoutes = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "/",
        element: <MessengerPage />,
      },
      {
        path: "/transparent-office",
        element: <TransparentOfficePage />,
      },
      {
        path: "/analytic-center",
        element: <AnalyticsCenterPage />,
      },
      {
        path: "/profile",
        element: <ProfilePage />,
        children: [
          {
            path: "general",
            element: <div>General</div>,
          },
          {
            path: "security",
            element: <div>Security</div>,
          },
          {
            path: "notifications",
            element: <div>Notifications</div>,
          },
        ],
      },
    ],
  },
]);

const authRouter = createBrowserRouter([
  {
    path: "/",
    element: <AuthPage />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "/sign-in",
        element: <SigninPage />,
      },
      {
        path: "/sign-up",
        element: <SignupPage />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
]);

function App() {
  return (
    <div className="App">
      <AppHeader />

      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
}

export default App;

export const LoadingApp = () => {
  const isAuth = useSelector((state: RootState) => selectAuthState(state));

  return (
    <>
      {/* {isAuth ? (
        <RouterProvider router={appRouter} />
      ) : (
        <RouterProvider router={authRouter} />
      )} */}
      <RouterProvider router={landingRoutes} />
    </>
  );
};
