import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import styles from "./TopNavBar.module.scss";
import { ReactComponent as IconMessenger } from "../../assets/svg/icon-deals.svg";
import { ReactComponent as IconOffice } from "../../assets/svg/icon-file.svg";
import { ReactComponent as IconAnalytic } from "../../assets/svg/icon-analytics.svg";
import gsap from "gsap";

const navLinks = [
  {
    title: "Messenger Accrede",
    path: "/",
    active: true,
    key: "ma",
  },
  {
    title: "Transparent Office",
    path: "/transparent-office",
    active: false,
    key: "to",
  },
  {
    title: "Analytic center",
    path: "/analytic-center",
    active: false,
    key: "ac",
  },
];

export const TopNavBar = () => {
  const selectorLineRef = React.useRef<HTMLDivElement>(null);

  const moveLine = (e: React.SyntheticEvent, index: number) => {
    const offSet =
      index === 1
        ? 0
        : index === 2
        ? //@ts-ignore
          e.currentTarget.previousSibling.getBoundingClientRect().width + 20
        : //@ts-ignore
          e.currentTarget.previousSibling?.previousSibling.getBoundingClientRect()
            .width +
          //@ts-ignore
          e.currentTarget.previousSibling.getBoundingClientRect().width +
          40;

    gsap.to(selectorLineRef.current, {
      duration: 0.3,
      ease: "power2.out",
      left: offSet,
      width: e.currentTarget.getBoundingClientRect().width,
    });
  };

  const shrinkLineAnimation = () => {
    // gsap.to(selectorLineRef.current, {
    //   duration: .320,
    //   ease: "power2.out",
    //   width: 188,
    //   y: 4,
    //   delay: 0.3,
    // })
  };

  useEffect(() => {
    shrinkLineAnimation();
  }, []);

  const isMainLink = () => {
    return navLinks.map((link) => link.path).includes(window.location.pathname);
  };

  return (
    <div className={styles.navHolder}>
      {isMainLink() && (
        <div className={styles.selectorLine} ref={selectorLineRef} />
      )}
      <nav>
        <NavLink to={"/"} onClick={(e) => moveLine(e, 1)}>
          <IconMessenger width={20} height={20} />
          Messenger Accrede
        </NavLink>

        <NavLink to={"/transparent-office"} onClick={(e) => moveLine(e, 2)}>
          <IconOffice width={20} height={20} />
          Transparent Office
        </NavLink>

        <NavLink to={"/analytic-center"} onClick={(e) => moveLine(e, 3)}>
          <IconAnalytic width={20} height={20} />
          Analytic center
        </NavLink>
      </nav>
    </div>
  );
};
