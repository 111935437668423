import React from "react";
import { ReactComponent as IconVerified } from "../assets/svg/icon-verified.svg";
import { ReactComponent as IconNotVerified } from "../assets/svg/icon-not-verified.svg";
import { ReactComponent as IconMenu } from "../assets/svg/icon-small-menu.svg";
import { AvatarsGroup } from "./AvatarsGroup";

type ParticipantCardProps = {
  role: "buyer" | "supplier" | "surveyor" | "logistician";
  title: string;
  verified: boolean;
};

export const ParticipantCard: React.FC<ParticipantCardProps> = ({
  role,
  title,
  verified,
}) => {
  const [menuOpened, setMenuOpened] = React.useState<boolean>(false);

  const toggleMenu = () => {
    setMenuOpened(!menuOpened);
  };
  return (
    <div
      style={{
        padding: 20,
        borderRadius: 10,
        backgroundColor: "#F1F1F1",
        marginBottom: 20,
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <div
          className="avatarHolder"
          style={{
            borderRadius: "100%",
            width: 48,
            height: 48,
            minWidth: 48,
            minHeight: 48,
            background: "#C1C7CD",
            marginRight: 10,
            position: "relative",
          }}
        >
          <div
            style={{
              width: 18,
              height: 18,
              borderRadius: "100%",
              border: "2px solid #fff",
              background: "#4D5358",
              position: "absolute",
              bottom: 0,
              left: -2,
            }}
          />
        </div>

        <div
          className="namesHolder"
          style={{
            fontSize: 18,
          }}
        >
          <div
            style={{
              fontWeight: 600,
              textTransform: "capitalize",
            }}
          >
            {role}
          </div>
          <div style={{ fontWeight: 300 }}>{title}</div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 6,
          fontWeight: 500,
          fontSize: 14,
          color: "rgba(0,0,0,0.5)",
        }}
      >
        {verified && (
          <>
            <IconVerified
              style={{ marginRight: 6, opacity: 0.5 }}
              strokeWidth={0.2}
              stroke={"black"}
            />{" "}
            Verified by&nbsp;
            <AvatarsGroup style={{ marginLeft: 6, opacity: 0.5 }} />
          </>
        )}
        {!verified && (
          <>
            <IconNotVerified style={{ marginRight: 6 }} /> Verification
          </>
        )}
      </div>

      <div
        style={{
          background: "#E1E1E1",
          borderRadius: "100%",
          width: 18,
          height: 18,
          position: "absolute",
          top: 10,
          right: 10,
          cursor: "pointer",
        }}
        onClick={toggleMenu}
      >
        <IconMenu style={{ opacity: 0.6 }} />
      </div>

      {menuOpened && (
        <div
          style={{
            transformOrigin: "top left",
            width: "max-content",
            position: "absolute",
            top: 30,
            left: "92%",
            background: "#E1E1E1",
            borderRadius: 6,
            fontSize: 12,
            padding: "8px 10px",
            zIndex: 20,
            cursor: "pointer",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
            fontWeight: 500,
          }}
          onClick={toggleMenu}
        >
          Verify this participant
        </div>
      )}
    </div>
  );
};
