import React from "react";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { ReactComponent as IconNotifications } from "../../assets/svg/icon-notifications.svg";
import styles from "../../app.module.scss";
import { TopNavBar } from "../TopNavBar/TopNavBar";
import { useNavigate } from "react-router-dom";
type AppHeaderProps = {
  showNav?: boolean;
};

export const AppHeader: React.FC<AppHeaderProps> = ({ showNav = true }) => {
  const nav = useNavigate();
  return (
    <header className={styles.header}>
      <div className={"mainWrapper"}>
        <Logo className={styles.logo} height={50} />
        {showNav && <TopNavBar />}

        {showNav && (
          <div className={styles.headerRight}>
            <IconNotifications />
            <div
              className={styles.user}
              onClick={() => nav("/profile/general")}
            >
              <div className={styles.userNameData}>
                <div className={styles.userName}>Wood Antique LLC</div>
                <div className={styles.userPosition}>subtitle and data</div>
              </div>
              <div className={styles.userAvatar} />
            </div>
          </div>
        )}
      </div>
    </header>
  );
};
