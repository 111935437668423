import dayjs from "dayjs";
import { MAIN_DATE_FORMAT } from "../constants/formats";
import { IDocType } from "../models/IDocType";

export const _mockSurveorDocs: IDocType[] = [
  {
    id: 'h97g8',
    title: "Quality report",
    fromDate: dayjs('02/23/2023', MAIN_DATE_FORMAT).valueOf(),
    expires: dayjs('04/23/2023', MAIN_DATE_FORMAT).valueOf(),
    type: "file",
  },
  {
    id: 'h297g8',
    title: "Quantity report",
    fromDate: dayjs('02/25/2023', MAIN_DATE_FORMAT).valueOf(),
    expires: dayjs('04/23/2023', MAIN_DATE_FORMAT).valueOf(),
    type: "file",
  }
]

export const _mockLogisticDocs: IDocType[] = [
  {
    id: 'h9g47g8',
    title: "Bill of lading",
    fromDate: dayjs('02/23/2023', MAIN_DATE_FORMAT).valueOf(),
    expires: dayjs('04/23/2023', MAIN_DATE_FORMAT).valueOf(),
    type: "file",
  },
]

export const _mockSupplierDocs: IDocType[] = [
  {
    id: 'h1987g8',
    title: "Invoice",
    fromDate: dayjs('02/30/2023', MAIN_DATE_FORMAT).valueOf(),
    type: "file",
  },
  {
    id: 'h198p7g8',
    title: "Origin certificate",
    fromDate: dayjs('02/30/2023', MAIN_DATE_FORMAT).valueOf(),
    type: "file",
  },
]