import React from "react";

type BaseInputProps = {
  inputType?: "text" | "password";
  value?: string;
  label?: string;
  valueChanged?: (text: string) => void;
  placeholder?: string;
};

export const BaseInput: React.FC<BaseInputProps> = ({
  value,
  valueChanged,
  label,
  placeholder,
  inputType = "text",
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: 20,
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      {label && (
        <label
          style={{
            marginBottom: 5,
            fontSize: 14,
            fontWeight: 500,
            fontFamily: "'Inter', sans-serif",
          }}
        >
          {label}
        </label>
      )}
      <input
        style={{
          padding: "8px 16px",
          border: "2px solid #343A3F",
          borderRadius: 8,
          fontSize: 14,
          width: "100%",
        }}
        type={inputType}
        value={value}
        onChange={(e) => valueChanged && valueChanged(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
};
