import React, { PropsWithChildren } from "react";

import styles from "./BaseCard.module.scss";

type BaseCardProps = {
  withShadow?: boolean;
  withPadding?: boolean;
};

export const BaseCard: React.FC<PropsWithChildren & BaseCardProps> = ({
  children,
  withShadow,
  withPadding,
}) => {
  return (
    <div
      className={
        styles.cardHolder + " " + (withShadow ? styles.shadowCard : null)
      }
      style={{ padding: withPadding ? "20px" : 0 }}
    >
      {children}
    </div>
  );
};
