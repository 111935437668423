import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './'

// Define a type for the slice state
interface InfoState {
  lang?: string,
}

// Define the initial state using that type
const initialState: InfoState = {
  lang: 'en',
}

export const infoSlice = createSlice({
  name: 'info',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload
    }
  },
})

export const { setLang } = infoSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectLangState = (state: RootState) => state.info.lang

export default infoSlice.reducer