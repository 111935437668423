import React from "react";

import styles from "./LettersList.module.scss";
import { ReactComponent as IconNext } from "../../assets/svg/icon-chevron-right.svg";

type LettersListProps = {
  listTitle: string;
  lettersList: any[];
  titleIcon?: any;
  selectedItemId: string;
  itemClicked: (itemId: string) => void;
  collapseble?: boolean;
  withLines?: boolean;
};

export const LettersList: React.FC<LettersListProps> = ({
  listTitle,
  titleIcon,
  lettersList,
  selectedItemId,
  itemClicked,
  collapseble,
  withLines,
}) => {
  const toggleList = () => {
    console.log("Toggle list, meow! :3");
  };

  return (
    <div>
      <div className={styles.listTitle}>
        <div style={{ fontSize: 14, display: "flex", alignItems: "center" }}>
          {titleIcon && <span style={{ marginRight: 6 }}>{titleIcon}</span>}
          {listTitle}
        </div>
        {collapseble && (
          <div className={styles.listTitleIcon} onClick={toggleList}>
            <IconNext />
          </div>
        )}
      </div>
      <ul className={styles.listHolder}>
        {lettersList.map((letter, index) => {
          return (
            <li
              key={letter.id}
              className={selectedItemId === letter.id ? styles.activeItem : ""}
              style={{
                border: withLines ? "1px solid #E5E5E5" : "none",
                borderTopWidth: index === 0 ? 1 : 0,
                borderLeft: 0,
                borderRight: 0,
                paddingLeft: withLines ? 48 : 20,
              }}
              onClick={() => itemClicked(letter.id)}
            >
              <div className={styles.itemInfo}>
                <div>{"LC #" + letter.id}</div>
                <span>{letter.info}</span>
              </div>
              <IconNext />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
