import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { LoadingApp } from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";
import { ThemeProvider } from "react-jss";
// eslint-disable-next-line
import Symbol_observable from "symbol-observable";

import ReactGA from "react-ga";

ReactGA.initialize("G-79B8C8KFTY");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={{}}>
        <LoadingApp />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
