import { useFrame, useThree } from "@react-three/fiber";
import React, { useEffect, useMemo, useRef } from "react";
import { BufferGeometry, Color, PointsMaterial, SphereGeometry } from "three";

const particleCount = 3800;

export const WavingSphere = () => {
  const viewport = useThree((state) => state.viewport);
  const { height } = viewport;

  const groupRef = useRef<any>(null);
  const geomRef = useRef<SphereGeometry>(null);
  const plRef = useRef<any>(null);
  const bufferGeometry = new BufferGeometry();
  const pointsMaterial = new PointsMaterial({
    color: "hotpink",
    sizeAttenuation: true,
    size: 0.015,
  });
  const particles = new Float32Array(particleCount * 3);

  const radius = height / 2;

  // useEffect(() => {
  for (let i = 0; i < particleCount; i++) {
    const u = i / particleCount;
    const v = Math.random();

    const r = i % 6 === 0 ? radius * Math.cbrt(Math.random()) : radius;

    const theta = 2 * Math.PI * u;
    const phi = Math.acos(2 * v - 1);

    const x = r * Math.sin(phi) * Math.cos(theta);
    const y = r * Math.sin(phi) * Math.sin(theta);
    const z = r * Math.cos(phi);

    particles[i * 3] = x;
    particles[i * 3 + 1] = y;
    particles[i * 3 + 2] = z;
  }
  // }, []);

  useFrame(({ clock }, delta) => {
    if (groupRef.current) {
      groupRef.current.rotation.y = clock.getElapsedTime() / 4;
      groupRef.current.rotation.z = -clock.getElapsedTime() / 4;
    }
    // Set position of the point light to the top point of the sphere
    if (plRef.current) {
      plRef.current.position.set(0, radius - 0.2, 0);
    }
  });

  const widthDelta = viewport.width - viewport.width / 2;

  // useHelper(groupRef, BoxHelper, new Color("cyan"));

  return (
    <group ref={groupRef} position={[viewport.top + widthDelta, -1, 0.3]}>
      {/* <pointLight ref={plRef} color={"#3BFCB6"} intensity={0.4} /> */}
      <points>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            array={particles}
            count={particles.length / 3}
            itemSize={3}
          />
        </bufferGeometry>
        <pointsMaterial color={new Color("cyan")} sizeAttenuation size={0.04} />

        {/* <sphereGeometry ref={geomRef} args={[radius, 118, 128]} />
        <meshStandardMaterial
          color={new Color("cyan")}
          metalness={0.5}
          roughness={0.4}
        /> */}
      </points>
    </group>
  );
};
