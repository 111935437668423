import React from 'react'

type BaseButtonProps = {
  title?: string;
  onClick?: () => void;
  secondary?: boolean;
}

export const BaseButton: React.FC<BaseButtonProps> = ({
  title,
  onClick,
  secondary,
}) => {
  const primaryStyle = {
    backgroundColor: '#343A3F',
    color: '#fff',
  }
  const secondaryStyle = {
    backgroundColor: '#fff',
    color: '#343A3F',
  }
  return (
    <div
      style={{
        border: '2px solid #343A3F',
        padding: '12px 16px',
        minWidth: 136,
        width: 'fit-content',
        borderRadius: 8,
        fontWeight: 700,
        textTransform: 'uppercase',
        textAlign: 'center',
        display: 'inline-block',
        fontSize: 14,
        fontFamily: 'Inter, sans-serif',
        boxShadow: "0 2px 4px rgba(58, 92, 144, 0.14), 0 3px 4px rgba(58, 92, 144, 0.12), 0 1px 5px rgba(58, 92, 144, 0.2)",
        marginBottom: 20,
        cursor: 'pointer',
        ...(secondary ? secondaryStyle : primaryStyle)
      }}
      onClick={() => onClick?.()}>
      {title}
    </div>
  )
}
