import { configureStore } from "@reduxjs/toolkit";
import infoReducer from "./info.slice";
import authReducer from "./auth.slice";

export const store = configureStore({
  reducer: {
    info: infoReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;